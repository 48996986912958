import ImageCardNew from "../../components/features/ImageCardNew.vue";
import VideoOverlay from '../../components/VideoOverlay.vue';
import AffiliateVideoCard from '../../components/AffiliateVideoCard.vue';


var homeApp = new Vue({
    el: "#pages-home-home-content",
    components: { ImageCardNew, VideoOverlay, AffiliateVideoCard },
    
    data() {
    	return {
    		healthNeeds: healthNeeds,

            trendingSearches: [
                { name: "Magnesium" },
                { name: "Collagen" },
                { name: "Zinc" },
                { name: "Iron" },
                { name: "Protein" },
            ],

            expertsAndAthletes: [
                {
                    title: "Erin Andrews",
                    subtitle: "Sports Broadcaster",
                    bgImage: "/images/pages/home/cards/erin-andrews-card.png",
                    link: "/u/erinandrews/about"
                },
                {
                    titleImage: "/images/pages/home/logos/musc.svg",
                    titleImageAlt: "Medical University of South Carolina logo",
                    hiddenTitle: "Medical University of South Carolina",
                    subtitle: "Health and Wellness Institute",
                    bgImage: "/images/pages/home/cards/musc-health-card.png",
                    link: "/products/set/MUSC"
                },
                {
                    title: "Dwyane Wade",
                    subtitle: "Pro Basketball Hall of Famer",
                    bgImage: "/images/pages/home/cards/dwyane-wade-card.png",
                    link: "/u/dwyanewade/about"
                },
                {
                    title: "Jrue Holiday",
                    subtitle: "Pro Basketball Champion",
                    bgImage: "/images/pages/home/cards/jrue-holiday-card.png",
                    link: "/u/jrueholiday/about"
                },
                {
                    title: "Jeff Nichols",
                    subtitle: "Special Forces",
                    bgImage: "/images/pages/home/cards/jeff-nichols-card.png",
                    link: "/u/performancefirstus"
                },
                {
                    title: "Madison Keys",
                    subtitle: "Pro Tennis Player",
                    bgImage: "/images/pages/home/cards/madison-keys-card.png",
                    link: "/u/madisonkeys"
                },
                {
                    titleImage: "/images/pages/home/logos/ufc.svg",
                    titleImageAlt: "Ultimate Fighting Championship logo",
                    hiddenTitle: "Ultimate Fighting Championship",
                    subtitle: "Mixed Martial Arts",
                    bgImage: "/images/pages/home/cards/ufc-mma-card.png",
                    link: "/u/PR713933"
                },
                {
                    title: "Daniel Ricciardo",
                    subtitle: "Formula 1 Driver",
                    bgImage: "/images/pages/home/cards/daniel-ricciardo-card.png",
                    link: "/u/danielricciardo"
                },
                {
                    titleImage: "/images/pages/home/logos/team-penske.svg",
                    titleImageAlt: "Team Penske logo",
                    hiddenTitle: "Team Penske",
                    subtitle: "NASCAR",
                    bgImage: "/images/pages/home/cards/team-penske-card.png",
                    link: "/u/TeamPenske"
                },
                {
                    titleImage: "/images/pages/home/logos/sorinex.svg",
                    titleImageAlt: "Sorinex",
                    hiddenTitle: "Sorinex",
                    subtitle: "Strength and Conditioning",
                    bgImage: "/images/pages/home/cards/sorinex-card.png",
                    link: "/products/set/sorinex"
                },
                {
                    title: "Coco Ho",
                    subtitle: "Pro Surfer",
                    bgImage: "/images/pages/home/cards/coco-ho-card.png",
                    link: "/u/cocohostorefront"
                },
                {
                    title: "Team USA",
                    subtitle: "USA Rugby",
                    bgImage: "/images/pages/home/cards/usa-rugby-card.png",
                    link: "/u/USARugby"
                },
                {
                    title: "Don Saladino",
                    subtitle: "Coach and Health Entrepreneur",
                    bgImage: "/images/pages/home/cards/don-saladino-card.png",
                    link: "/u/donsaladino"
                },
                {
                    titleImage: "/images/pages/home/logos/erewhon.svg",
                    titleImageAlt: "Erewhon logo",
                    hiddenTitle: "Erewhon",
                    subtitle: "Holistic Marketplace",
                    bgImage: "/images/pages/home/cards/erewhon-card.png",
                    link: "/products/set/erewhon"
                },
                {
                    titleImage: "/images/pages/home/logos/team-liquid.svg",
                    titleImageAlt: "Team Liquid logo",
                    hiddenTitle: "Team Liquid",
                    subtitle: "Esports Team",
                    bgImage: "/images/pages/home/cards/team-liquid-card.png",
                    link: "/u/teamliquid/about"
                },
            ],
    	}
    },

    methods: {

        pathToCdn(path) {
            return this.$cdn(path);
        }

    }

});

